@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* font-family: 'Signika', sans-serif; */
/*
*
/// Global ///
*
*/
:root {
  --primary-color: #ff0f38;
  --primary-color-mate: #fff2f2;
  --secondary-color: #231f20;
  --primary-font-color: #231f20;
  --secondary-font-color: #fff;
  --white: #fff;
  --black: #000;
  --spacer: 16px;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
*:focus {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li {
  font-weight: 400;
}
a {
  text-decoration: none;
  color: var(--main-font-color);
  position: relative;
}
.appWrapper {
  overflow: hidden;
}
.iconWrapper {
  display: none;
  align-items: center;
  position: relative;
  width: 70px;
  height: 70px;
  color: #ff0f38;
  color: var(--primary-color);
}
.iconInnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.iconWrapper .placeholderSVG,
.iconWrapper .animatedSVG,
.iconWrapper .closeSVG {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconWrapper .placeholderSVG svg,
.iconWrapper .animatedSVG svg,
.iconWrapper .closeSVG svg {
  position: absolute;
}
.iconWrapper .animatedSVG svg path {
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.iconWrapper:hover .placeholderSVG svg,
.iconWrapper.menuOpen .placeholderSVG svg,
.iconWrapper.menuOpen .animatedSVG svg {
  opacity: 0;
  transition: 0.25s opacity ease;
}
.iconWrapper:hover .animatedSVG svg * {
  stroke-width: 2;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s forwards linear;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(1) {
  animation-delay: 0.1s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(2) {
  animation-delay: 0.3s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(3) {
  animation-delay: 0.5s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(4) {
  animation-delay: 0.7s;
}
@keyframes draw {
  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
  }
}
.iconWrapper .closeSVG svg {
  opacity: 0;
  transition: 0.25s opacity ease;
}
.iconWrapper.menuOpen .closeSVG svg {
  opacity: 1;
  transition: 0.25s opacity ease;
}
/*
*
/// Main ///
*
*/
.mainOuterWrapper {
  min-height: 100vh;
}
.pageOutterWrapper {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bannerWrapper {
  max-height: 50vh;
  width: 100vw;
  overflow: hidden;
}
.bannerBg {
  background-image: url("/assets/imgs/museu-st-antonio.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
}
.pageContentWrapper {
  width: 80%;
  margin: 2rem 0 8rem;
}
.pageContentWrapper h1 {
  margin: 1rem 0;
  font-weight: 700;
}
.pageContentWrapper p {
  margin: 1rem 0;
}
.pageContentWrapper .textTitle {
  text-transform: uppercase;
}
.pageContentWrapper .textSpacer {
  border: none;
}
.pageContentWrapper .textSpacer.big {
  height: 4rem;
}
.pageContentWrapper .textSpacer.medium {
  height: 2rem;
}
.pageContentWrapper .textFeatured {
  font-weight: 700;
}
/*
*
/// Timeline ///
*
*/
.bgFull,
.bgFullOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.bgFull {
  background-image: url(/assets/imgs/bgFull.jpg);
  z-index: -2;
}
.bgFullOverlay {
  background-color: rgba(50, 50, 50, 0.7);
  z-index: -1;
}
.timelineOuterWrapper {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.timelineWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: calc(100vh - 70px);
  width: 100vw;
}
.timelineNavWrapper {
  position: absolute;
  width: 100%;
  min-height: 40px;
  top: calc(50% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timelineNavWrapper::after {
  position: absolute;
  width: 100%;
  top: calc(50% - 2px);
  height: 4px;
  content: "";
  background-color: #ff0f38;
  background-color: var(--primary-color);
}
.timelineNavWrapper .leftIcon,
.timelineNavWrapper .rightIcon {
  color: #231f20;
  color: var(--secondary-color);
  position: absolute;
  z-index: 99;
  animation: pulsate 3s ease-in-out infinite;
}
.timelineNavWrapper .leftIcon {
  left: 0;
}
.timelineNavWrapper .rightIcon {
  right: 0;
}
@keyframes pulsate {
  0% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
}
.timelineNavInnerWrapper {
  display: flex;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.timelineNavInnerWrapper .prevItemsWrapper,
.timelineNavInnerWrapper .nextItemsWrapper {
  align-items: center;
}
.timelineNavInnerWrapper h5 {
  min-width: 100px;
  padding: 0.2rem 0;
  text-align: center;
  background-color: #ff0f38;
  background-color: var(--primary-color);
  font-size: 1.2rem;
  color: #fff;
  color: var(--secondary-font-color);
  font-weight: 700;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
}
.timelineNavInnerWrapper h6 {
  min-width: 50px;
  padding: 0.1rem 0;
  text-align: center;
  background-color: #ff0f38;
  background-color: var(--primary-color);
  font-size: 0.7rem;
  color: #fff;
  color: var(--secondary-font-color);
  font-weight: 700;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
}
.timelineNavWrapper .nextItemInnerWrapper {
  display: flex;
  flex-direction: column;
}
.itemsWrapper p {
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}
.itemsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
  margin: 0 auto;
}
.vidaTrackWrapper,
.otherTrackWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 45%;
}
.bgHeading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.bgHeading h2 {
  color: rgba(255, 255, 255, 0.03);
  font-size: 2rem;
  font-weight: 700;
}
.otherTrackWrapper .bgHeading h2 {
  font-size: 2rem;
}
.prevItemsWrapper,
.nextItemsWrapper,
.activeItemWrapper {
  position: relative;
}
.itemsWrapper .year {
  position: absolute;
  top: -10%;
  font-weight: 700;
  font-size: 2rem;
  color: #ff0f38;
  color: var(--primary-color);
}
.prevItemsWrapper,
.nextItemsWrapper {
  display: none;
  align-items: flex-start;
  width: 20%;
}
.prevItem,
.nextItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.prevItemsWrapper .prevItem:nth-of-type(1),
.nextItemsWrapper .nextItem:nth-of-type(2) {
  display: none;
}
.activeItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.cardImage {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  background-position: center center;
  width: 50%;
  height: auto;
  min-height: 200px;
  margin: 0 0.5rem;
}
.itemCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 0;
  width: 100%;
  perspective: 300px;
}
.itemCard.prev .cardImage,
.itemCard.next .cardImage {
  opacity: 0.3;
  transform-style: preserve-3d;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
  -moz-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
  mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
}
.itemCard.next .cardImage {
  transform: rotateX(0) rotateY(-30deg);
}
.itemCard.prev .cardImage {
  transform: rotateX(0) rotateY(30deg);
}
.cardContent {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0.5rem;
}
.mainTimelineBtnWrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.mainTimelineBtn {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  width: 30vw;
  height: 100vh;
  border: none;
  background: none;
}
.mainTimelineBtn.prev {
  left: 0;
}
.mainTimelineBtn.next {
  right: 0;
}
#tCulto .otherTrackWrapper .cardImage {
  display: none;
}
#tCulto .itemCard .content p {
  font-size: 0.9rem;
}
#tCulto .otherTrackWrapper {
  padding-top: unset;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #tCulto .itemCard .content p {
    font-size: 1rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .iconWrapper {
    display: flex;
  }
  .bgHeading h2 {
    font-size: 4rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 4rem;
  }
  .prevItemsWrapper,
  .nextItemsWrapper {
    display: flex;
  }
  .activeItemWrapper {
    width: 60%;
  }
  .cardContent {
    width: 100%;
    padding: 1rem;
  }
  .cardImage {
    width: 100%;
    margin: 0;
  }
  .itemsWrapper {
    width: 90%;
  }
  .itemsWrapper p {
    font-size: 0.75rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .mainOuterWrapper {
    min-height: 100vh;
  }
  .pageOutterWrapper {
    margin-left: 70px;
  }
  .bannerWrapper {
    max-height: 70vh;
  }
  .bannerBg {
    height: 70vh;
  }
  .pageContentWrapper h1 {
    margin: 2rem 0;
    font-weight: 700;
  }
  .pageContentWrapper p {
    margin: 1rem 0;
  }
  .bgHeading h2 {
    font-size: 6rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 6rem;
  }
  .timelineWrapper {
    height: 100vh;
  }
  .timelineNavWrapper .leftIcon,
  .timelineNavWrapper .rightIcon {
    display: none;
  }
  .activeItemWrapper {
    width: 40%;
  }
  .prevItemsWrapper,
  .nextItemsWrapper {
    width: 30%;
  }
  .prevItem,
  .nextItem {
    width: 50%;
  }
  .itemCard {
    flex-direction: column;
    width: 90%;
  }
  .prevItemsWrapper .prevItem:nth-of-type(1),
  .nextItemsWrapper .nextItem:nth-of-type(2) {
    display: flex;
  }
  #tCulto .itemCard .content p {
    font-size: 0.8rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .bgHeading h2 {
    font-size: 8rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 8rem;
  }
}

.msaV_white .fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688 {
  fill: #fff;
}
.msaV_white .a920dc74-825c-4820-9369-d2ec3720323d {
  fill: #e11a27;
}
.msaH_white .ea5ab9d2-b24a-4075-a404-93a8735ef2c3 {
  fill: #fff;
}
.msaH_white .a29a30e0-434b-4aeb-8fdf-bb4dbddfa696 {
  fill: #e11a27;
}
.e4fb219d-54ec-4d90-86b6-f027ee37ee3a {
  fill: #fff;
}
.msaH_white #egeac_logo {
  max-width: 25%;
  margin-right: 1rem;
}

.navOuterWrapper {
  position: fixed;
  z-index: 999;
  bottom: 0;
  height: 70px;
  width: 100vw;
}
.navbarWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  background: var(--secondary-color);
  /* background: linear-gradient(180deg, rgba(35,31,32,1) 0%, rgba(97,63,63,1) 40%, rgba(97,63,63,1) 60%, rgba(35,31,32,1) 100%); */
  z-index: 99;
  box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.btnsWrapper {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBtnWrapper,
.navFooterWrapper {
  width: 25%;
  height: 100%;
}
.msaV_white {
  max-width: 50%;
  display: none;
  margin: 0 auto;
}
.msaV_white svg {
  margin-top: 1rem;
}
.msaH_white {
  height: 100%;
  margin: 0 1rem;
  display: flex;
  align-items: center;
}
.msaH_white svg {
  width: auto;
  height: 40%;
}
.homeBtnWrapper a {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.homeBtnWrapper img {
  max-width: 75%;
}
.mainBtnWrapper {
  background-color: var(--primary-color);
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  position: relative;
  transition: 1s background-color ease,
    0.3s transform cubic-bezier(0.215, 0.61, 0.355, 1);
  display: flex;
  padding: 0;
  width: 16vw;
  height: 16vw;
  margin-left: 1vw;
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.mainBtnWrapper .mainBtn {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 6vw;
  height: 4.1vw;
  top: calc(50% - 2vw);
  left: calc(50% - 3vw);
}
.mainBtnWrapper .mainBtn span {
  width: 100%;
  height: 0.285vw;
  background-color: var(--secondary-color);
  transition: 0.3s transform ease, 0.3s opacity ease;
}
.mainBtnWrapper .mainBtn span:nth-of-type(2) {
  width: 80%;
}
.mainBtnWrapper:hover .mainBtn span:nth-of-type(2) {
  transform: translateX(20%);
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
  transform: translateY(1.8vw) rotate(45deg) scaleX(0.9);
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(2) {
  display: none;
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
  transform: translateY(-1.8vw) rotate(-45deg) scaleX(0.9);
}
/*
*
/// Drawers ///
*
*/
.navOuterWrapper .navbarDrawer {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 98;
  color: #231f20;
  background-color: var(--white);
  visibility: hidden;
  overflow: hidden;
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s color cubic-bezier(0.77, 0, 0.175, 1),
    0.3s transform cubic-bezier(0.77, 0, 0.175, 1), 0.3s visibility;
}
.navOuterWrapper .navbarDrawer.menuOpen {
  transform: translateY(-100%);
  visibility: visible;
}
.drawerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.drawerWrapper .contactsEntryImg {
  height: 30%;
}
.drawerWrapper .contactsEntryImg img {
  height: 100%;
  width: auto;
}
.drawerWrapper .content {
  width: 80%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drawerWrapper .content h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.drawerWrapper .content .adressWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
.drawerWrapper .content p {
  font-size: 1rem;
  margin: 0.25rem 0;
  display: flex;
  align-items: center;
}
.drawerWrapper .content p span {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
a.primaryBtn {
  padding: 0.1rem 0.25rem;
}
.drawerWrapper .content a.map {
  margin-top: 3rem;
}
a.primaryBtn:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
  z-index: -1;
  transition: 0.3s height ease-in-out;
}
a.primaryBtn:hover {
  color: var(--secondary-font-color);
}
a.primaryBtn:hover:after {
  height: 100%;
  transition: 0.3s height ease-in-out;
}
.drawerWrapper .content .main {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.drawerWrapper .content .main h2 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 1rem 0;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .main h2:hover {
  opacity: 0.5;
  transition: 0.5s opacity ease-in-out;
}
.timelineNavLinks .menuBtnWrapper {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .main + .timelineNavLinks h2 {
  pointer-events: none;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 1rem 0;
  transition: 0.5s opacity ease-in-out;
}
.drawerWrapper .content .main + .timelineNavLinks:hover h2 {
  opacity: 0.5;
  transition: 0.5s opacity ease-in-out;
}
.timelineNavLinks:hover .menuBtnWrapper {
  height: 150px;
  overflow: auto;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .timelineNavLinks .menuBtnWrapper a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 400;
  font-size: 1rem;
}
#mainBtn.drawerWrapper .content {
  align-items: flex-start;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .mainBtnWrapper {
    width: 12vw;
    height: 12vw;
  }
  .mainBtnWrapper .mainBtn {
    width: 5vw;
    height: 3vw;
    top: calc(50% - 5vw / 2);
    left: calc(50% - 5vw / 2);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
    transform: translateY(1.3vw) rotate(45deg) scaleX(0.9);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
    transform: translateY(-1.3vw) rotate(-45deg) scaleX(0.9);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navOuterWrapper {
    right: unset;
    height: 100vh;
    width: 70px;
  }
  .navbarWrapper {
    flex-direction: column;
  }
  .navOuterWrapper .navbarDrawer {
    left: 70px;
    top: 0;
    bottom: 0;
    width: 36vw;
    height: 100vh;
    transform: translateX(-100%);
  }
  .navOuterWrapper .navbarDrawer.menuOpen {
    transform: none;
  }
  .homeBtnWrapper,
  .navFooterWrapper {
    height: 25%;
    width: 100%;
  }
  .msaV_white {
    display: block;
  }
  .msaH_white {
    display: none;
  }
  .btnsWrapper {
    width: 100%;
    height: 40%;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainBtnWrapper {
    width: 5vw;
    height: 5vw;
  }
  .mainBtnWrapper .mainBtn {
    width: 2.5vw;
    height: 1.75vw;
    top: calc(50% - 1.75vw / 2);
    left: calc(50% - 2.5vw / 2);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
    transform: translateY(0.75vw) rotate(45deg) scaleX(0.9);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
    transform: translateY(-0.7vw) rotate(-45deg) scaleX(0.9);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

#dragWall {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  cursor: -webkit-grab;
  cursor: grab;
}
#dragWall.pageOutterWrapper {
  margin-left: 0;
}
.wallWrapper {
  display: flex;
  width: 150%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 50%;
}
.wallWrapperColumn {
  padding-left: 0;
  background-clip: padding-box;
}
.wallElCard {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  pointer-events: all;
}
.wallElCard img {
  pointer-events: none;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.wallCatNavWrapper {
  display: flex;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 2%;
}
.wallCatNav {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 175px;
  width: 200px;
  transition: 1.5s all ease;
}
.wallCatNav.hidden {
  height: 0;
  width: 0;
  transition: 1.5s all ease 0.5s;
}
.catNavIcon {
  stroke: var(--white);
  opacity: 1;
  transition: 1.5s opacity ease 1s;
  transition: 0.5s background-color ease;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--primary-color);
}
.catNavIcon:hover {
  background-color: rgba(255, 15, 56, 0.7);
  transition: 0.5s background-color ease;
}
.catNavIcon.hidden {
  opacity: 0;
  transition: 0.5s opacity ease;
}
.wallCatBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  background-color: var(--primary-color);
  margin: 0.25rem;
  opacity: 1;
  transition: 0.4s opacity ease;
  cursor: pointer;
}
.wallCatBtn.hidden {
  opacity: 0.6;
  transition: 0.4s opacity ease;
}
.wallCatBtn:hover {
  opacity: 1;
  transition: 0.4s opacity ease;
}
.wallCatNav .wallCatBtn a {
  opacity: 1;
  transition: 0.4s opacity ease 1s;
}
.wallCatNav.hidden .wallCatBtn a {
  opacity: 0;
  transition: 1s opacity ease;
}
.wallCatBtn a {
  width: 75%;
  color: var(--white);
  font-size: 0.9rem;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0.25rem 0rem 0.25rem 1rem;
}
.wallCatBtn span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  min-height: 26px;
  color: var(--primary-color);
  margin-left: 0.5rem;
  margin-right: 2px;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 50%;
  background-color: #fff;
}
#WallPopUp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.97);
  height: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 999;
  pointer-events: none;
}
#WallPopUp.open {
  height: 100vh;
  pointer-events: initial;
}
.popupClose {
  position: absolute;
  right: 4rem;
  top: 4rem;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 50%;
  border: 2px solid var(--white);
  transition: 0.4s all ease;
}
.popupClose:hover {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  transition: 0.4s all ease;
}
.popupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 3rem 0;
}
.popupWrapper .popupHalf {
  display: flex;
  height: 100%;
}
.popupWrapper .popupHalf.img {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.popupWrapper .popupHalf.img img {
  height: auto;
  width: 75%;
}
.popupWrapper .popupHalf.content {
  width: 75%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--white);
  margin-top: 2rem;
  padding-bottom: 6rem;
}
.popupWrapper .popupHalf.content h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.popupWrapper .popupHalf.content p {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}
.wallIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 5% 0 5%;
  overflow: auto;
}
.wallIntro img {
  width: auto;
  height: 35vh;
}
.wallIntro h1 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.wallIntroBtn {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 700;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
  width: 80%;
  margin: 2rem 0;
}
.wallIntroBtn:hover {
  background-color: rgba(255, 15, 56, 0.7);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.wallIntro p {
  font-size: 0.8rem;
  margin: 1rem 0;
  text-align: center;
  line-height: 1.5;
}
.dragInfo {
  position: absolute;
  z-index: 99;
  background-color: rgba(100, 100, 100, 0.9);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dragInfo img {
  width: auto;
  height: 100px;
}
.dragInfo p {
  color: var(--primary-color-mate);
  font-size: 0.9rem;
  margin: 2rem;
  text-align: center;
}
.dragInfo .addedInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dragInfoFilterIcon {
  stroke: var(--white);
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--primary-color);
}
.dragInfo .addedInfo p {
  margin-top: 0.25rem;
}
.dragInfo button {
  background-color: rgba(200, 200, 200, 0.5);
  color: var(--primary-color-mate);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.dragInfo button:hover {
  background-color: rgba(255, 15, 56, 0.8);
  color: var(--primary-color-mate);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.fichaTecnica {
  margin-top: 4rem !important;
  margin-bottom: 2rem !important;
}
.fichaTecnica p {
  margin: 0.25rem 0 !important;
}
.cardWrapper {
  position: relative;
}
@media (min-width: 768px) {
  .wallIntro {
    padding: 2rem 20% 0 20%;
  }
  .wallIntro img {
    width: auto;
    height: 70vh;
  }
  .wallIntro h1 {
    font-size: 2rem;
  }
  .wallIntro p {
    font-size: 0.9rem;
  }
  .wallIntroBtn {
    width: 50%;
  }
  .wallCatNav {
    flex-direction: row;
    width: 650px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .wallIntroBtn {
    width: 30%;
  }
  .popupWrapper {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
  .popupWrapper .popupHalf.img {
    width: 60%;
  }
  .popupWrapper .popupHalf.img img {
    height: 75%;
    width: auto;
  }
  .popupWrapper .popupHalf.content {
    width: 40%;
    margin-top: 0;
    padding-bottom: 0;
  }
  .popupWrapper .popupHalf.content h2 {
    font-size: 2rem;
  }
  .popupWrapper .popupHalf.content p {
    font-size: 0.9rem;
  }
  #dragWall.pageOutterWrapper {
    margin-left: 35px;
  }
}
@media (min-width: 1200px) {
  .wallWrapper {
    width: 200%;
    margin-left: 100%;
  }
  .wallWrapperColumn {
    padding-left: 10px;
  }
  .wallCatNav {
    flex-direction: row;
    width: 650px;
  }
  .wallElCard {
    max-width: 260px;
  }
}

:root {
  --greenWall: #1ecc1d;
  --orangeWall: #ffa800;
  --blueWall: #33b7ce;
}

.tronosWall .wallIntro {
  width: 100%;
  background-color: #1ecc1d;
  background-color: var(--greenWall);
}
#dragWall.tronosWall.Participantes {
  background-color: #33b7ce;
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores {
  background-color: #ffa800;
  background-color: var(--orangeWall);
}
#dragWall.wallWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 0;
}
.tronosWall .wallWrapper {
  padding-top: 2%;
  margin-top: 0;
}
.tronosWall .wallIntro .bannerWrapper {
  width: 100%;
  min-height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1ecc1d;
  background-color: var(--greenWall);
}
.tronosWall .wallIntro .bannerWrapper img {
  width: auto;
  height: 100%;
}
.tronosWall .contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.tronosWall .wallIntroBtn {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}
.tronosWall .wallIntroBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
#dragWall.tronosWall .wallCatBtn {
  background-color: #1ecc1d;
  background-color: var(--greenWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Participantes .wallCatBtn {
  background-color: #33b7ce;
  background-color: var(--blueWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Participantes .wallCatBtn span {
  color: #33b7ce;
  color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores .wallCatBtn {
  background-color: #ffa800;
  background-color: var(--orangeWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Vencedores .wallCatBtn span {
  color: #ffa800;
  color: var(--orangeWall);
}
.tronosWall .wallCatBtn span {
  color: #1ecc1d;
  color: var(--greenWall);
}
.tronosWall .catNavIcon {
  background-color: #1ecc1d;
  background-color: var(--greenWall);
  border: 2px solid #fff;
}
.tronosWall .catNavIcon:hover {
  background-color: rgba(30, 204, 29, 0.7);
  transition: 0.5s background-color ease;
}
#dragWall.tronosWall.Participantes .catNavIcon {
  background-color: #33b7ce;
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Participantes .catNavIcon:hover {
  background-color: #33b7ce;
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores .catNavIcon {
  background-color: #ffa800;
  background-color: var(--orangeWall);
}
#dragWall.tronosWall.Vencedores .catNavIcon:hover {
  background-color: #ffa800;
  background-color: var(--orangeWall);
}
.tronosWall .wallCatNav {
  width: 300px;
}
.tronosWall .wallCatNav.hidden {
  width: 0;
}
#dragWall.tronosWall .ribbon {
  display: none;
}
#dragWall.tronosWall.Vencedores .ribbon {
  display: inline;
  display: initial;
  background-color: var(--white);
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0 0 10px 10px;
}
#dragWall.tronosWall.Vencedores .ribbon {
  color: #cacaca;
}
#dragWall.tronosWall.Vencedores .ribbon.Vencedor {
  color: gold;
}
@media (min-width: 768px) {
  .tronosWall .wallIntro .bannerWrapper {
    min-height: 50vh;
  }
}

@media (min-width: 992px) {
  .tronosWall .contentWrapper {
    width: 60%;
  }
  .tronosWall .wallIntroBtn {
    width: 50%;
  }
}
@media (min-width: 1200px) {
}

.card-container,
.card,
.card .side {
  width: 100%;
  height: 100%;
}
.card-container {
  cursor: pointer;
  perspective: 600px;
}
.card {
  border-radius: 10px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #cacaca;
}
.card .side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
}
.card .back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .back.msaV_white {
  max-width: 100%;
}
.card .back svg {
  opacity: 0.8;
  height: 40%;
}
.card .back.valhaStoAntonio {
  background-image: url(/assets/memoryGameImgs/valhaStoAntonio_CardBack.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.card-container:hover .card {
  border: 1px solid #231f20;
  box-shadow: 0 0 10px rgba(#231f20, 0.6);
}
.card-container:hover .card .back::after {
  opacity: 0.5;
}
.card-container:hover .card.flipped {
  border: 1px solid #231f20;
}

.card.flipped {
  border: 1px solid #231f20;
  transform: rotateY(180deg);
}

.gameWrapper {
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
}
.gameNav {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}
.gameNav .selectors {
  display: flex;
}
.gameNav .gameSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  margin-right: 1rem;
}
.gameNav .gameSelect label {
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1em 0 0.5em;
  letter-spacing: 1px;
  color: #c0c6c4;
}
.gameSelect select {
  display: block;
  font-size: 0.65rem;
  font-weight: 700;
  color: #231f20;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.gameSelect select::-ms-expand {
  display: none;
}
.gameSelect select:hover {
  border-color: #888;
}
.gameSelect select:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.gameSelect select option {
  font-weight: normal;
}
button.playAgain {
  background: #ededed;
  font-size: 0.65rem;
  font-weight: 700;
  color: #231f20;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

button.playAgain:active {
  background: #e5e5e5;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}
.gameWrapper .cardsWrapper {
  width: 70vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: var(--spacer) * 6;
  display: grid;
  grid-gap: var(--spacer);
  margin-top: 0.5rem;
}
.gameWrapper .cardsWrapper.level-8 {
  grid-template-columns: repeat(3, auto);
}
.gameWrapper .cardsWrapper.level-16 {
  grid-template-columns: repeat(4, auto);
}
.gameWrapper .cardsWrapper.level-24 {
  grid-template-columns: repeat(5, auto);
}
.gameWrapper .cardsWrapper.level-32 {
  grid-template-columns: repeat(6, auto);
}
.successInfo {
  position: absolute;
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  border: 2px solid #231f20;
  opacity: 1;
  transition: 0.5s opacity ease 1s;
  pointer-events: all;
}
.successInfo.hidden {
  opacity: 0;
  transition: 0.5s opacity ease;
  pointer-events: none;
}
.successInfo h2 {
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.successInfo h6 {
  font-size: 1.2rem;
  margin-bottom: 4rem;
}
.successInfo .close {
  position: absolute;
  top: 5%;
  right: 5%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 1px solid #231f20;
}
.successInfo .close:hover {
  font-weight: 600;
}

@media (min-width: 768px) {
  .gameWrapper {
    height: 85vh;
  }
  .gameWrapper .cardsWrapper {
    width: 75vw;
    margin-bottom: 3rem;
  }
  .gameWrapper .cardsWrapper.level-8 {
    grid-template-columns: repeat(4, auto);
  }
  .gameWrapper .cardsWrapper.level-16 {
    grid-template-columns: repeat(6, auto);
  }
  .gameWrapper .cardsWrapper.level-24 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-32 {
    grid-template-columns: repeat(8, auto);
  }
  .successInfo {
    padding: 6rem;
  }
}

@media (min-width: 992px) {
  .gameWrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 auto;
  }
  .gameNav {
    width: 15vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .gameNav .selectors {
    flex-direction: column;
  }
  .gameWrapper .cardsWrapper {
    width: 70vw;
    margin-bottom: 0;
  }
  .gameWrapper .cardsWrapper.level-8 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-16 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-24 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-32 {
    grid-template-columns: repeat(8, auto);
  }
  .successInfo {
    padding: 10rem;
  }
  .gameNav .gameSelect {
    margin-top: 2rem;
  }
}
@media (min-width: 1200px) {
}

.stopWatch {
  display: flex;
}
.stopWatch h6 {
  font-size: 2.5rem;
  margin-right: 0.75rem;
}
.stopWatch span {
  font-size: 1.2rem;
  margin-left: 0.2rem;
}

