#dragWall {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  cursor: grab;
}
#dragWall.pageOutterWrapper {
  margin-left: 0;
}
.wallWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 150%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 50%;
}
.wallWrapperColumn {
  padding-left: 0;
  background-clip: padding-box;
}
.wallElCard {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  pointer-events: all;
}
.wallElCard img {
  pointer-events: none;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.wallCatNavWrapper {
  display: flex;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 2%;
}
.wallCatNav {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 175px;
  width: 200px;
  transition: 1.5s all ease;
}
.wallCatNav.hidden {
  height: 0;
  width: 0;
  transition: 1.5s all ease 0.5s;
}
.catNavIcon {
  stroke: var(--white);
  opacity: 1;
  transition: 1.5s opacity ease 1s;
  transition: 0.5s background-color ease;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--primary-color);
}
.catNavIcon:hover {
  background-color: rgba(255, 15, 56, 0.7);
  transition: 0.5s background-color ease;
}
.catNavIcon.hidden {
  opacity: 0;
  transition: 0.5s opacity ease;
}
.wallCatBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  background-color: var(--primary-color);
  margin: 0.25rem;
  opacity: 1;
  transition: 0.4s opacity ease;
  cursor: pointer;
}
.wallCatBtn.hidden {
  opacity: 0.6;
  transition: 0.4s opacity ease;
}
.wallCatBtn:hover {
  opacity: 1;
  transition: 0.4s opacity ease;
}
.wallCatNav .wallCatBtn a {
  opacity: 1;
  transition: 0.4s opacity ease 1s;
}
.wallCatNav.hidden .wallCatBtn a {
  opacity: 0;
  transition: 1s opacity ease;
}
.wallCatBtn a {
  width: 75%;
  color: var(--white);
  font-size: 0.9rem;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0.25rem 0rem 0.25rem 1rem;
}
.wallCatBtn span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  min-height: 26px;
  color: var(--primary-color);
  margin-left: 0.5rem;
  margin-right: 2px;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 50%;
  background-color: #fff;
}
#WallPopUp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.97);
  height: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 999;
  pointer-events: none;
}
#WallPopUp.open {
  height: 100vh;
  pointer-events: initial;
}
.popupClose {
  position: absolute;
  right: 4rem;
  top: 4rem;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 50%;
  border: 2px solid var(--white);
  transition: 0.4s all ease;
}
.popupClose:hover {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  transition: 0.4s all ease;
}
.popupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 3rem 0;
}
.popupWrapper .popupHalf {
  display: flex;
  height: 100%;
}
.popupWrapper .popupHalf.img {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.popupWrapper .popupHalf.img img {
  height: auto;
  width: 75%;
}
.popupWrapper .popupHalf.content {
  width: 75%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--white);
  margin-top: 2rem;
  padding-bottom: 6rem;
}
.popupWrapper .popupHalf.content h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.popupWrapper .popupHalf.content p {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}
.wallIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 5% 0 5%;
  overflow: auto;
}
.wallIntro img {
  width: auto;
  height: 35vh;
}
.wallIntro h1 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.wallIntroBtn {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 700;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
  width: 80%;
  margin: 2rem 0;
}
.wallIntroBtn:hover {
  background-color: rgba(255, 15, 56, 0.7);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.wallIntro p {
  font-size: 0.8rem;
  margin: 1rem 0;
  text-align: center;
  line-height: 1.5;
}
.dragInfo {
  position: absolute;
  z-index: 99;
  background-color: rgba(100, 100, 100, 0.9);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dragInfo img {
  width: auto;
  height: 100px;
}
.dragInfo p {
  color: var(--primary-color-mate);
  font-size: 0.9rem;
  margin: 2rem;
  text-align: center;
}
.dragInfo .addedInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dragInfoFilterIcon {
  stroke: var(--white);
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--primary-color);
}
.dragInfo .addedInfo p {
  margin-top: 0.25rem;
}
.dragInfo button {
  background-color: rgba(200, 200, 200, 0.5);
  color: var(--primary-color-mate);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.dragInfo button:hover {
  background-color: rgba(255, 15, 56, 0.8);
  color: var(--primary-color-mate);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
.fichaTecnica {
  margin-top: 4rem !important;
  margin-bottom: 2rem !important;
}
.fichaTecnica p {
  margin: 0.25rem 0 !important;
}
.cardWrapper {
  position: relative;
}
@media (min-width: 768px) {
  .wallIntro {
    padding: 2rem 20% 0 20%;
  }
  .wallIntro img {
    width: auto;
    height: 70vh;
  }
  .wallIntro h1 {
    font-size: 2rem;
  }
  .wallIntro p {
    font-size: 0.9rem;
  }
  .wallIntroBtn {
    width: 50%;
  }
  .wallCatNav {
    flex-direction: row;
    width: 650px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .wallIntroBtn {
    width: 30%;
  }
  .popupWrapper {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
  .popupWrapper .popupHalf.img {
    width: 60%;
  }
  .popupWrapper .popupHalf.img img {
    height: 75%;
    width: auto;
  }
  .popupWrapper .popupHalf.content {
    width: 40%;
    margin-top: 0;
    padding-bottom: 0;
  }
  .popupWrapper .popupHalf.content h2 {
    font-size: 2rem;
  }
  .popupWrapper .popupHalf.content p {
    font-size: 0.9rem;
  }
  #dragWall.pageOutterWrapper {
    margin-left: 35px;
  }
}
@media (min-width: 1200px) {
  .wallWrapper {
    width: 200%;
    margin-left: 100%;
  }
  .wallWrapperColumn {
    padding-left: 10px;
  }
  .wallCatNav {
    flex-direction: row;
    width: 650px;
  }
  .wallElCard {
    max-width: 260px;
  }
}
