.card-container,
.card,
.card .side {
  width: 100%;
  height: 100%;
}
.card-container {
  cursor: pointer;
  perspective: 600px;
}
.card {
  border-radius: 10px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #cacaca;
}
.card .side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
}
.card .back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .back.msaV_white {
  max-width: 100%;
}
.card .back svg {
  opacity: 0.8;
  height: 40%;
}
.card .back.valhaStoAntonio {
  background-image: url(/assets/memoryGameImgs/valhaStoAntonio_CardBack.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.card-container:hover .card {
  border: 1px solid #231f20;
  box-shadow: 0 0 10px rgba(#231f20, 0.6);
}
.card-container:hover .card .back::after {
  opacity: 0.5;
}
.card-container:hover .card.flipped {
  border: 1px solid #231f20;
}

.card.flipped {
  border: 1px solid #231f20;
  transform: rotateY(180deg);
}
