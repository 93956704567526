@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika:wght@400;700&display=swap");
/* font-family: 'Signika', sans-serif; */
/*
*
/// Global ///
*
*/
:root {
  --primary-color: #ff0f38;
  --primary-color-mate: #fff2f2;
  --secondary-color: #231f20;
  --primary-font-color: #231f20;
  --secondary-font-color: #fff;
  --white: #fff;
  --black: #000;
  --spacer: 16px;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
*:focus {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li {
  font-weight: 400;
}
a {
  text-decoration: none;
  color: var(--main-font-color);
  position: relative;
}
.appWrapper {
  overflow: hidden;
}
.iconWrapper {
  display: none;
  align-items: center;
  position: relative;
  width: 70px;
  height: 70px;
  color: var(--primary-color);
}
.iconInnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.iconWrapper .placeholderSVG,
.iconWrapper .animatedSVG,
.iconWrapper .closeSVG {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconWrapper .placeholderSVG svg,
.iconWrapper .animatedSVG svg,
.iconWrapper .closeSVG svg {
  position: absolute;
}
.iconWrapper .animatedSVG svg path {
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.iconWrapper:hover .placeholderSVG svg,
.iconWrapper.menuOpen .placeholderSVG svg,
.iconWrapper.menuOpen .animatedSVG svg {
  opacity: 0;
  transition: 0.25s opacity ease;
}
.iconWrapper:hover .animatedSVG svg * {
  stroke-width: 2;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s forwards linear;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(1) {
  animation-delay: 0.1s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(2) {
  animation-delay: 0.3s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(3) {
  animation-delay: 0.5s;
}
.iconWrapper:hover .animatedSVG svg *:nth-child(4) {
  animation-delay: 0.7s;
}
@keyframes draw {
  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
  }
}
.iconWrapper .closeSVG svg {
  opacity: 0;
  transition: 0.25s opacity ease;
}
.iconWrapper.menuOpen .closeSVG svg {
  opacity: 1;
  transition: 0.25s opacity ease;
}
/*
*
/// Main ///
*
*/
.mainOuterWrapper {
  min-height: 100vh;
}
.pageOutterWrapper {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bannerWrapper {
  max-height: 50vh;
  width: 100vw;
  overflow: hidden;
}
.bannerBg {
  background-image: url("/assets/imgs/museu-st-antonio.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
}
.pageContentWrapper {
  width: 80%;
  margin: 2rem 0 8rem;
}
.pageContentWrapper h1 {
  margin: 1rem 0;
  font-weight: 700;
}
.pageContentWrapper p {
  margin: 1rem 0;
}
.pageContentWrapper .textTitle {
  text-transform: uppercase;
}
.pageContentWrapper .textSpacer {
  border: none;
}
.pageContentWrapper .textSpacer.big {
  height: 4rem;
}
.pageContentWrapper .textSpacer.medium {
  height: 2rem;
}
.pageContentWrapper .textFeatured {
  font-weight: 700;
}
/*
*
/// Timeline ///
*
*/
.bgFull,
.bgFullOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.bgFull {
  background-image: url(/assets/imgs/bgFull.jpg);
  z-index: -2;
}
.bgFullOverlay {
  background-color: rgba(50, 50, 50, 0.7);
  z-index: -1;
}
.timelineOuterWrapper {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.timelineWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: calc(100vh - 70px);
  width: 100vw;
}
.timelineNavWrapper {
  position: absolute;
  width: 100%;
  min-height: 40px;
  top: calc(50% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timelineNavWrapper::after {
  position: absolute;
  width: 100%;
  top: calc(50% - 2px);
  height: 4px;
  content: "";
  background-color: var(--primary-color);
}
.timelineNavWrapper .leftIcon,
.timelineNavWrapper .rightIcon {
  color: var(--secondary-color);
  position: absolute;
  z-index: 99;
  -webkit-animation: pulsate 3s ease-in-out infinite;
  -moz-animation: pulsate 3s ease-in-out infinite;
  -o-animation: pulsate 3s ease-in-out infinite;
  animation: pulsate 3s ease-in-out infinite;
}
.timelineNavWrapper .leftIcon {
  left: 0;
}
.timelineNavWrapper .rightIcon {
  right: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
}
@-moz-keyframes pulsate {
  0% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0.3;
  }
}
.timelineNavInnerWrapper {
  display: flex;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.timelineNavInnerWrapper .prevItemsWrapper,
.timelineNavInnerWrapper .nextItemsWrapper {
  align-items: center;
}
.timelineNavInnerWrapper h5 {
  min-width: 100px;
  padding: 0.2rem 0;
  text-align: center;
  background-color: var(--primary-color);
  font-size: 1.2rem;
  color: var(--secondary-font-color);
  font-weight: 700;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
}
.timelineNavInnerWrapper h6 {
  min-width: 50px;
  padding: 0.1rem 0;
  text-align: center;
  background-color: var(--primary-color);
  font-size: 0.7rem;
  color: var(--secondary-font-color);
  font-weight: 700;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
}
.timelineNavWrapper .nextItemInnerWrapper {
  display: flex;
  flex-direction: column;
}
.itemsWrapper p {
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}
.itemsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
  margin: 0 auto;
}
.vidaTrackWrapper,
.otherTrackWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 45%;
}
.bgHeading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.bgHeading h2 {
  color: rgba(255, 255, 255, 0.03);
  font-size: 2rem;
  font-weight: 700;
}
.otherTrackWrapper .bgHeading h2 {
  font-size: 2rem;
}
.prevItemsWrapper,
.nextItemsWrapper,
.activeItemWrapper {
  position: relative;
}
.itemsWrapper .year {
  position: absolute;
  top: -10%;
  font-weight: 700;
  font-size: 2rem;
  color: var(--primary-color);
}
.prevItemsWrapper,
.nextItemsWrapper {
  display: none;
  align-items: flex-start;
  width: 20%;
}
.prevItem,
.nextItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.prevItemsWrapper .prevItem:nth-of-type(1),
.nextItemsWrapper .nextItem:nth-of-type(2) {
  display: none;
}
.activeItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.cardImage {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  background-position: center center;
  width: 50%;
  height: auto;
  min-height: 200px;
  margin: 0 0.5rem;
}
.itemCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 0;
  width: 100%;
  perspective: 300px;
}
.itemCard.prev .cardImage,
.itemCard.next .cardImage {
  opacity: 0.3;
  transform-style: preserve-3d;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
  -moz-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
  mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(black),
    color-stop(0.4, black),
    to(transparent)
  );
}
.itemCard.next .cardImage {
  transform: rotateX(0) rotateY(-30deg);
}
.itemCard.prev .cardImage {
  transform: rotateX(0) rotateY(30deg);
}
.cardContent {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0.5rem;
}
.mainTimelineBtnWrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.mainTimelineBtn {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  width: 30vw;
  height: 100vh;
  border: none;
  background: none;
}
.mainTimelineBtn.prev {
  left: 0;
}
.mainTimelineBtn.next {
  right: 0;
}
#tCulto .otherTrackWrapper .cardImage {
  display: none;
}
#tCulto .itemCard .content p {
  font-size: 0.9rem;
}
#tCulto .otherTrackWrapper {
  padding-top: unset;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #tCulto .itemCard .content p {
    font-size: 1rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .iconWrapper {
    display: flex;
  }
  .bgHeading h2 {
    font-size: 4rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 4rem;
  }
  .prevItemsWrapper,
  .nextItemsWrapper {
    display: flex;
  }
  .activeItemWrapper {
    width: 60%;
  }
  .cardContent {
    width: 100%;
    padding: 1rem;
  }
  .cardImage {
    width: 100%;
    margin: 0;
  }
  .itemsWrapper {
    width: 90%;
  }
  .itemsWrapper p {
    font-size: 0.75rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .mainOuterWrapper {
    min-height: 100vh;
  }
  .pageOutterWrapper {
    margin-left: 70px;
  }
  .bannerWrapper {
    max-height: 70vh;
  }
  .bannerBg {
    height: 70vh;
  }
  .pageContentWrapper h1 {
    margin: 2rem 0;
    font-weight: 700;
  }
  .pageContentWrapper p {
    margin: 1rem 0;
  }
  .bgHeading h2 {
    font-size: 6rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 6rem;
  }
  .timelineWrapper {
    height: 100vh;
  }
  .timelineNavWrapper .leftIcon,
  .timelineNavWrapper .rightIcon {
    display: none;
  }
  .activeItemWrapper {
    width: 40%;
  }
  .prevItemsWrapper,
  .nextItemsWrapper {
    width: 30%;
  }
  .prevItem,
  .nextItem {
    width: 50%;
  }
  .itemCard {
    flex-direction: column;
    width: 90%;
  }
  .prevItemsWrapper .prevItem:nth-of-type(1),
  .nextItemsWrapper .nextItem:nth-of-type(2) {
    display: flex;
  }
  #tCulto .itemCard .content p {
    font-size: 0.8rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .bgHeading h2 {
    font-size: 8rem;
  }
  .otherTrackWrapper .bgHeading h2 {
    font-size: 8rem;
  }
}
