.stopWatch {
  display: flex;
}
.stopWatch h6 {
  font-size: 2.5rem;
  margin-right: 0.75rem;
}
.stopWatch span {
  font-size: 1.2rem;
  margin-left: 0.2rem;
}
