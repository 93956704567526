.navOuterWrapper {
  position: fixed;
  z-index: 999;
  bottom: 0;
  height: 70px;
  width: 100vw;
}
.navbarWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  background: var(--secondary-color);
  /* background: linear-gradient(180deg, rgba(35,31,32,1) 0%, rgba(97,63,63,1) 40%, rgba(97,63,63,1) 60%, rgba(35,31,32,1) 100%); */
  z-index: 99;
  -webkit-box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.btnsWrapper {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBtnWrapper,
.navFooterWrapper {
  width: 25%;
  height: 100%;
}
.msaV_white {
  max-width: 50%;
  display: none;
  margin: 0 auto;
}
.msaV_white svg {
  margin-top: 1rem;
}
.msaH_white {
  height: 100%;
  margin: 0 1rem;
  display: flex;
  align-items: center;
}
.msaH_white svg {
  width: auto;
  height: 40%;
}
.homeBtnWrapper a {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.homeBtnWrapper img {
  max-width: 75%;
}
.mainBtnWrapper {
  background-color: var(--primary-color);
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  position: relative;
  transition: 1s background-color ease,
    0.3s -webkit-transform cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 1s background-color ease,
    0.3s transform cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 1s background-color ease,
    0.3s transform cubic-bezier(0.215, 0.61, 0.355, 1),
    0.3s -webkit-transform cubic-bezier(0.215, 0.61, 0.355, 1);
  display: flex;
  padding: 0;
  width: 16vw;
  height: 16vw;
  margin-left: 1vw;
  -webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.mainBtnWrapper .mainBtn {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 6vw;
  height: 4.1vw;
  top: calc(50% - 2vw);
  left: calc(50% - 3vw);
}
.mainBtnWrapper .mainBtn span {
  width: 100%;
  height: 0.285vw;
  background-color: var(--secondary-color);
  transition: 0.3s transform ease, 0.3s opacity ease;
}
.mainBtnWrapper .mainBtn span:nth-of-type(2) {
  width: 80%;
}
.mainBtnWrapper:hover .mainBtn span:nth-of-type(2) {
  transform: translateX(20%);
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
  -webkit-transform: translateY(1.8vw) rotate(45deg) scaleX(0.9);
  transform: translateY(1.8vw) rotate(45deg) scaleX(0.9);
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(2) {
  display: none;
}
.mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
  -webkit-transform: translateY(-1.8vw) rotate(-45deg) scaleX(0.9);
  transform: translateY(-1.8vw) rotate(-45deg) scaleX(0.9);
}
/*
*
/// Drawers ///
*
*/
.navOuterWrapper .navbarDrawer {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  z-index: 98;
  color: #231f20;
  background-color: var(--white);
  visibility: hidden;
  overflow: hidden;
  -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s color cubic-bezier(0.77, 0, 0.175, 1), 0.3s visibility,
    0.3s -webkit-transform cubic-bezier(0.77, 0, 0.175, 1);
  transition: 0.3s color cubic-bezier(0.77, 0, 0.175, 1),
    0.3s transform cubic-bezier(0.77, 0, 0.175, 1), 0.3s visibility;
  transition: 0.3s color cubic-bezier(0.77, 0, 0.175, 1),
    0.3s transform cubic-bezier(0.77, 0, 0.175, 1), 0.3s visibility,
    0.3s -webkit-transform cubic-bezier(0.77, 0, 0.175, 1);
}
.navOuterWrapper .navbarDrawer.menuOpen {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: visible;
}
.drawerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.drawerWrapper .contactsEntryImg {
  height: 30%;
}
.drawerWrapper .contactsEntryImg img {
  height: 100%;
  width: auto;
}
.drawerWrapper .content {
  width: 80%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drawerWrapper .content h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.drawerWrapper .content .adressWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
.drawerWrapper .content p {
  font-size: 1rem;
  margin: 0.25rem 0;
  display: flex;
  align-items: center;
}
.drawerWrapper .content p span {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
a.primaryBtn {
  padding: 0.1rem 0.25rem;
}
.drawerWrapper .content a.map {
  margin-top: 3rem;
}
a.primaryBtn:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
  z-index: -1;
  transition: 0.3s height ease-in-out;
}
a.primaryBtn:hover {
  color: var(--secondary-font-color);
}
a.primaryBtn:hover:after {
  height: 100%;
  transition: 0.3s height ease-in-out;
}
.drawerWrapper .content .main {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.drawerWrapper .content .main h2 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 1rem 0;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .main h2:hover {
  opacity: 0.5;
  transition: 0.5s opacity ease-in-out;
}
.timelineNavLinks .menuBtnWrapper {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .main + .timelineNavLinks h2 {
  pointer-events: none;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 1rem 0;
  transition: 0.5s opacity ease-in-out;
}
.drawerWrapper .content .main + .timelineNavLinks:hover h2 {
  opacity: 0.5;
  transition: 0.5s opacity ease-in-out;
}
.timelineNavLinks:hover .menuBtnWrapper {
  height: 150px;
  overflow: auto;
  transition: 0.5s height ease-in-out;
}
.drawerWrapper .content .timelineNavLinks .menuBtnWrapper a {
  width: fit-content;
  font-weight: 400;
  font-size: 1rem;
}
#mainBtn.drawerWrapper .content {
  align-items: flex-start;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .mainBtnWrapper {
    width: 12vw;
    height: 12vw;
  }
  .mainBtnWrapper .mainBtn {
    width: 5vw;
    height: 3vw;
    top: calc(50% - 5vw / 2);
    left: calc(50% - 5vw / 2);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
    -webkit-transform: translateY(1.3vw) rotate(45deg) scaleX(0.9);
    transform: translateY(1.3vw) rotate(45deg) scaleX(0.9);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
    -webkit-transform: translateY(-1.3vw) rotate(-45deg) scaleX(0.9);
    transform: translateY(-1.3vw) rotate(-45deg) scaleX(0.9);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navOuterWrapper {
    right: unset;
    height: 100vh;
    width: 70px;
  }
  .navbarWrapper {
    flex-direction: column;
  }
  .navOuterWrapper .navbarDrawer {
    left: 70px;
    top: 0;
    bottom: 0;
    width: 36vw;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .navOuterWrapper .navbarDrawer.menuOpen {
    transform: none;
  }
  .homeBtnWrapper,
  .navFooterWrapper {
    height: 25%;
    width: 100%;
  }
  .msaV_white {
    display: block;
  }
  .msaH_white {
    display: none;
  }
  .btnsWrapper {
    width: 100%;
    height: 40%;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainBtnWrapper {
    width: 5vw;
    height: 5vw;
  }
  .mainBtnWrapper .mainBtn {
    width: 2.5vw;
    height: 1.75vw;
    top: calc(50% - 1.75vw / 2);
    left: calc(50% - 2.5vw / 2);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(1) {
    -webkit-transform: translateY(0.75vw) rotate(45deg) scaleX(0.9);
    transform: translateY(0.75vw) rotate(45deg) scaleX(0.9);
  }
  .mainBtnWrapper .mainBtn.menuOpen span:nth-of-type(3) {
    -webkit-transform: translateY(-0.7vw) rotate(-45deg) scaleX(0.9);
    transform: translateY(-0.7vw) rotate(-45deg) scaleX(0.9);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
