.msaV_white .fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688 {
  fill: #fff;
}
.msaV_white .a920dc74-825c-4820-9369-d2ec3720323d {
  fill: #e11a27;
}
.msaH_white .ea5ab9d2-b24a-4075-a404-93a8735ef2c3 {
  fill: #fff;
}
.msaH_white .a29a30e0-434b-4aeb-8fdf-bb4dbddfa696 {
  fill: #e11a27;
}
.e4fb219d-54ec-4d90-86b6-f027ee37ee3a {
  fill: #fff;
}
.msaH_white #egeac_logo {
  max-width: 25%;
  margin-right: 1rem;
}
