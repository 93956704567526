.gameWrapper {
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
}
.gameNav {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}
.gameNav .selectors {
  display: flex;
}
.gameNav .gameSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  margin-right: 1rem;
}
.gameNav .gameSelect label {
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1em 0 0.5em;
  letter-spacing: 1px;
  color: #c0c6c4;
}
.gameSelect select {
  display: block;
  font-size: 0.65rem;
  font-weight: 700;
  color: #231f20;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.gameSelect select::-ms-expand {
  display: none;
}
.gameSelect select:hover {
  border-color: #888;
}
.gameSelect select:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.gameSelect select option {
  font-weight: normal;
}
button.playAgain {
  background: #ededed;
  font-size: 0.65rem;
  font-weight: 700;
  color: #231f20;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

button.playAgain:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}
.gameWrapper .cardsWrapper {
  width: 70vw;
  height: fit-content;
  margin: var(--spacer) * 6;
  display: grid;
  grid-gap: var(--spacer);
  margin-top: 0.5rem;
}
.gameWrapper .cardsWrapper.level-8 {
  grid-template-columns: repeat(3, auto);
}
.gameWrapper .cardsWrapper.level-16 {
  grid-template-columns: repeat(4, auto);
}
.gameWrapper .cardsWrapper.level-24 {
  grid-template-columns: repeat(5, auto);
}
.gameWrapper .cardsWrapper.level-32 {
  grid-template-columns: repeat(6, auto);
}
.successInfo {
  position: absolute;
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  border: 2px solid #231f20;
  opacity: 1;
  transition: 0.5s opacity ease 1s;
  pointer-events: all;
}
.successInfo.hidden {
  opacity: 0;
  transition: 0.5s opacity ease;
  pointer-events: none;
}
.successInfo h2 {
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.successInfo h6 {
  font-size: 1.2rem;
  margin-bottom: 4rem;
}
.successInfo .close {
  position: absolute;
  top: 5%;
  right: 5%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 1px solid #231f20;
}
.successInfo .close:hover {
  font-weight: 600;
}

@media (min-width: 768px) {
  .gameWrapper {
    height: 85vh;
  }
  .gameWrapper .cardsWrapper {
    width: 75vw;
    margin-bottom: 3rem;
  }
  .gameWrapper .cardsWrapper.level-8 {
    grid-template-columns: repeat(4, auto);
  }
  .gameWrapper .cardsWrapper.level-16 {
    grid-template-columns: repeat(6, auto);
  }
  .gameWrapper .cardsWrapper.level-24 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-32 {
    grid-template-columns: repeat(8, auto);
  }
  .successInfo {
    padding: 6rem;
  }
}

@media (min-width: 992px) {
  .gameWrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 auto;
  }
  .gameNav {
    width: 15vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .gameNav .selectors {
    flex-direction: column;
  }
  .gameWrapper .cardsWrapper {
    width: 70vw;
    margin-bottom: 0;
  }
  .gameWrapper .cardsWrapper.level-8 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-16 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-24 {
    grid-template-columns: repeat(8, auto);
  }
  .gameWrapper .cardsWrapper.level-32 {
    grid-template-columns: repeat(8, auto);
  }
  .successInfo {
    padding: 10rem;
  }
  .gameNav .gameSelect {
    margin-top: 2rem;
  }
}
@media (min-width: 1200px) {
}
