:root {
  --greenWall: #1ecc1d;
  --orangeWall: #ffa800;
  --blueWall: #33b7ce;
}

.tronosWall .wallIntro {
  width: 100%;
  background-color: var(--greenWall);
}
#dragWall.tronosWall.Participantes {
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores {
  background-color: var(--orangeWall);
}
#dragWall.wallWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 0;
}
.tronosWall .wallWrapper {
  padding-top: 2%;
  margin-top: 0;
}
.tronosWall .wallIntro .bannerWrapper {
  width: 100%;
  min-height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--greenWall);
}
.tronosWall .wallIntro .bannerWrapper img {
  width: auto;
  height: 100%;
}
.tronosWall .contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.tronosWall .wallIntroBtn {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}
.tronosWall .wallIntroBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  transition: 0.4s all ease;
}
#dragWall.tronosWall .wallCatBtn {
  background-color: var(--greenWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Participantes .wallCatBtn {
  background-color: var(--blueWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Participantes .wallCatBtn span {
  color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores .wallCatBtn {
  background-color: var(--orangeWall);
  border: 2px solid #fff;
}
#dragWall.tronosWall.Vencedores .wallCatBtn span {
  color: var(--orangeWall);
}
.tronosWall .wallCatBtn span {
  color: var(--greenWall);
}
.tronosWall .catNavIcon {
  background-color: var(--greenWall);
  border: 2px solid #fff;
}
.tronosWall .catNavIcon:hover {
  background-color: rgba(30, 204, 29, 0.7);
  transition: 0.5s background-color ease;
}
#dragWall.tronosWall.Participantes .catNavIcon {
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Participantes .catNavIcon:hover {
  background-color: var(--blueWall);
}
#dragWall.tronosWall.Vencedores .catNavIcon {
  background-color: var(--orangeWall);
}
#dragWall.tronosWall.Vencedores .catNavIcon:hover {
  background-color: var(--orangeWall);
}
.tronosWall .wallCatNav {
  width: 300px;
}
.tronosWall .wallCatNav.hidden {
  width: 0;
}
#dragWall.tronosWall .ribbon {
  display: none;
}
#dragWall.tronosWall.Vencedores .ribbon {
  display: initial;
  background-color: var(--white);
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0 0 10px 10px;
}
#dragWall.tronosWall.Vencedores .ribbon {
  color: #cacaca;
}
#dragWall.tronosWall.Vencedores .ribbon.Vencedor {
  color: gold;
}
@media (min-width: 768px) {
  .tronosWall .wallIntro .bannerWrapper {
    min-height: 50vh;
  }
}

@media (min-width: 992px) {
  .tronosWall .contentWrapper {
    width: 60%;
  }
  .tronosWall .wallIntroBtn {
    width: 50%;
  }
}
@media (min-width: 1200px) {
}
